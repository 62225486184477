import React from "react";
import { ActionButton } from "halifax";
import {
  useCheckoutState,
  useCheckoutStateSelector as useSelector,
} from "@capone/checkout";

import { CONTINUE_TEXT } from "./textConstants";
import { Event, TEvent } from "../../state/events";
import { ExperiencesMachineContext } from "../../state/types";
import { PickupPoint, SelectLang, SpecialRequirementArea } from "./components";
import styles from "./styles.module.scss";
import { ExperiencesAdditionalDetailsSelectors } from "../../../../checkout";

export const AdditionalDetails = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_state, send] = useCheckoutState<TEvent, ExperiencesMachineContext>();

  const pickupLocations = useSelector(
    ExperiencesAdditionalDetailsSelectors.getPickupLocations
  );
  const productOptionLangGuides = useSelector(
    ExperiencesAdditionalDetailsSelectors.getBookableLangGuides
  );
  const hasSpecialRequirementField = useSelector(
    ExperiencesAdditionalDetailsSelectors.getHasSpecialRequirementField
  );

  return (
    <>
      <div className={styles["additional-details-container"]}>
        {pickupLocations?.isPickupIncluded && <PickupPoint />}
        {productOptionLangGuides.length > 1 && <SelectLang />}
        {hasSpecialRequirementField && <SpecialRequirementArea />}
      </div>
      <ActionButton
        onClick={() => send(Event.NEXT)}
        message={CONTINUE_TEXT}
        className="experiences-continue-button"
      />
    </>
  );
};
