import React from "react";
import { useCheckoutStateSelector as useSelector } from "@capone/checkout";
import {
  ExperiencesAdditionalDetailsSelectors,
  PickupPointOption,
} from "../../../../../checkout";

export const PickupPointCompleted = () => {
  const {
    selectedPickupPoint,
    hotelSelection,
    hotelName,
    address,
    city,
    zipCode,
  } = useSelector(ExperiencesAdditionalDetailsSelectors.getPickupPointStates);

  if (!selectedPickupPoint) {
    return null;
  }

  let pickupPoint;

  switch (selectedPickupPoint) {
    case PickupPointOption.SelectPickup: {
      if (hotelSelection?.name === "I don't see my hotel") {
        pickupPoint = [hotelName, address, city, zipCode].join(", ");
      } else {
        pickupPoint = `${hotelSelection?.name} ${hotelSelection?.address}`;
      }
      break;
    }
    case PickupPointOption.PickupLater:
      pickupPoint = "Arrange pickup later";
      break;
    case PickupPointOption.NoPickup:
      pickupPoint = "Meet tour operator at starting point";
      break;
    default:
      pickupPoint = "";
  }

  return <div>{pickupPoint}</div>;
};
