import {
  getBookableLangGuides,
  getPickupLocations,
  getPickupPointStates,
  getSelectedLangGuide,
} from "../selectors";

import { ExperiencesContext } from "../../../../modules/book/state/types";
import { LangGuideValidation, PickupPointValidation } from "../types";
import { PickupPointOption } from "../context";

export const validateAdditionalDetailsService = (context: ExperiencesContext) =>
  new Promise((resolve, reject) => {
    const validationErrors = [];
    const pickupLocations = getPickupLocations({ context });
    const bookableLangGuides = getBookableLangGuides({ context });
    const selectedLangGuide = getSelectedLangGuide({ context });

    if (pickupLocations?.isPickupIncluded) {
      const {
        selectedPickupPoint,
        hotelSelection,
        hotelName,
        address,
        city,
        zipCode,
      } = getPickupPointStates({ context });

      if (!selectedPickupPoint) {
        validationErrors.push(PickupPointValidation.PickupPointSelection);
      }

      if (
        selectedPickupPoint === PickupPointOption.SelectPickup &&
        !hotelSelection
      ) {
        validationErrors.push(PickupPointValidation.HotelSelection);
      }

      if (
        hotelSelection?.name === "I don't see my hotel" &&
        (!hotelName || !address || !city || !zipCode)
      ) {
        validationErrors.push(PickupPointValidation.EnterPickupLocation);
      }
    }

    if (bookableLangGuides.length > 1 && !selectedLangGuide) {
      validationErrors.push(LangGuideValidation.LangSelection);
    }

    if (validationErrors.length > 0) {
      reject(validationErrors);
    } else {
      resolve([]);
    }
  });
