import { State } from "xstate";
import { CartSelectors } from "@capone/checkout";
import {
  BookingQuestionAnswer,
  BookingQuestionId,
  ExperiencesPriceBreakdown,
  ExperiencesShopResponse,
  IPassengerCounts,
  Person,
  SpecificAgeBand,
} from "redmond";
import { Product } from "@b2bportal/purchase-api";
import dayjs from "dayjs";

import { ExperiencesMachineContext } from "../types";
import {
  BookingQuestionResponse,
  ExperiencesAdditionalDetailsSelectors,
  ExperiencesTravelerSelectors,
} from "../../../../checkout";

type ExperienceStateType = State<ExperiencesMachineContext>;
type ExperienceStateWithoutValue = Pick<ExperienceStateType, "context">;

export const getExperienceShop = (
  state: ExperienceStateWithoutValue
): ExperiencesShopResponse | undefined => state.context.experienceShop;

export const getExperienceTravelerCounts = (
  state: ExperienceStateWithoutValue
): IPassengerCounts | undefined => state.context.travelerCounts;

export const getExperiencePriceBreakdown = (
  state: ExperienceStateWithoutValue
): ExperiencesPriceBreakdown | undefined => {
  const quoteBreakdown = CartSelectors.getQuoteBreakdown(state);
  const quotedExperiencesProduct = quoteBreakdown?.products.find(
    (product) => product.product.type === ("Experiences" as Product)
  );

  const quotedExperiencesProductValue = quotedExperiencesProduct?.product.value
    .breakdown as ExperiencesPriceBreakdown | undefined;

  // adding date to quote price breakdown as well.
  if (quotedExperiencesProductValue && state.context?.priceBreakdown?.date) {
    quotedExperiencesProductValue.date = state.context.priceBreakdown.date;
  }

  return quotedExperiencesProductValue || state.context.priceBreakdown;
};

const getTravelersAnswerPerQuestion = (
  id: BookingQuestionId,
  travelers: Person[],
  responses?: BookingQuestionResponse,
  ageBands?: SpecificAgeBand[]
) =>
  travelers.map((traveler, idx) => {
    const { weight = "", heightFt, heightIn } = responses?.[traveler.id] || {};

    let answer = "";
    let unit = "";

    switch (id) {
      case BookingQuestionId.Height: {
        answer = `${heightFt}'${heightIn}`;
        unit = "ft";
        break;
      }
      case BookingQuestionId.Weight: {
        answer = weight;
        unit = "lbs";
        break;
      }
      case BookingQuestionId.FullNamesFirst: {
        answer = traveler.givenName;
        break;
      }
      case BookingQuestionId.FullNamesLast: {
        answer = traveler.surname;
        break;
      }
      case BookingQuestionId.Ageband: {
        answer = "TRAVELER"; // default to Traveler
        if (ageBands && ageBands.length > 0) {
          const age = dayjs().diff(dayjs(traveler.dateOfBirth), "year");

          ageBands.forEach(({ upperBound, lowerBound, ageBand }) => {
            if (age >= lowerBound && age <= upperBound) {
              answer = ageBand.toUpperCase();
            }
          });
        }
        break;
      }
      case BookingQuestionId.DateOfBirth: {
        answer = dayjs(traveler.dateOfBirth).format("DD-MM-YYYY");
        break;
      }
      case BookingQuestionId.PassportPassportNo: {
        answer = traveler.passport?.number || "";
        break;
      }
      case BookingQuestionId.PassportExpiry: {
        answer = traveler.passport?.expiration || "";
        break;
      }
      case BookingQuestionId.PassportNationality: {
        answer = traveler.passport?.countryOfIssue || "";
        break;
      }
      default:
    }

    return unit
      ? {
          bookingQuestionId: id,
          travelerNum: idx + 1,
          unit,
          answer,
        }
      : {
          bookingQuestionId: id,
          travelerNum: idx + 1,
          answer,
        };
  });

export const getBookingQuestionAnswers = ({
  context,
}: ExperienceStateWithoutValue): BookingQuestionAnswer[] => {
  const perTravelerBookingQuestions =
    context.experienceShop?.bookingQuestions?.perTraveler || [];
  const ageBand = context.experienceShop?.ageBands;
  const travelers = ExperiencesTravelerSelectors.getAllSelectedUserTravelers({
    context,
  });

  const specialRequirement =
    ExperiencesAdditionalDetailsSelectors.getSpecialRequirement({ context });

  const travelerBookingAnswers: BookingQuestionAnswer[] = [];
  if (perTravelerBookingQuestions.length > 0) {
    perTravelerBookingQuestions.forEach(({ id }) => {
      const answers = getTravelersAnswerPerQuestion(
        id,
        travelers,
        context.experiencesTravelerInformation.bookingQuestionResponse,
        ageBand
      );
      travelerBookingAnswers.push(...answers);
    });
  }

  // TODO add pickup location booking question later

  return [
    specialRequirement && {
      bookingQuestionId: BookingQuestionId.SpecialRequirements,
      answer: specialRequirement,
    },
    ...travelerBookingAnswers,
  ].filter((answer) => !!answer) as BookingQuestionAnswer[];
};
